
import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import AxiosHelper from '../../helper/AxiosHelper'

const initialState = {
    setting: {
        favicon: "",
        logo: "",
        application_name: "",
        copyright: "",
        address: "",
        email: "",
        phone: "",
        facebook: "",
        twitter: "",
        linkdin: "",
        instagram: "",
        notify_modal_show: "",
        notify_modal_content: "",
        load_money_qr_code: "",
        force_update_android: "",
        force_update_ios: "",
        app_version_android: "",
        app_version_ios: "",
        app_url_android: "",
        app_url_ios: "",
        force_update_message_android: "",
        force_update_message_ios: "",
        maintenance: "",
        maintenance_toggle: "",
        information_banner: "",
        information_banner_toggle: "",
        helpline_numbers: "",
        helpline_numbers_accounting: "",
        base_path: ""
    },
    status: "idle", // idle | loading | succeeded | failed
    error: ""
}

export const fetchSetting = createAsyncThunk("setting/fetchSetting", async () => {
    const { data } = await AxiosHelper.getData(`/settings`);
    if (data?.status === true) {
        return data?.data?.settings;
    }
})

const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        // ==> normal reducer functions go here
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSetting.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(fetchSetting.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.setting = action.payload;
            })
            .addCase(fetchSetting.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
    }
})

export default settingSlice.reducer;