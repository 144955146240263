import { Link } from "react-router-dom";
import ScrollButton from "./ScrollButton";
import { useSelector } from 'react-redux'

const Footer = (props) => {

    const { setting } = useSelector((state) => state.setting)
    let { logo, base_path, application_name, application_about, instagram, facebook, twitter, phone, email, address, copyright } = setting;

    return (
        <>
            <div className="container-fluid">
                <div className="row bg-footer text-white  myborder p-lg-5 p-md-4 p-4 ps-2 myfooter">
                    <div className="col-lg-3 col-md-6 col-12">
                        <img src={base_path + logo} alt={application_name} className="imgclass" />
                        <p className="footer-logo-text">{application_about} </p>
                        <br />
                        <ul className="d-flex align-items-center ulc1 p-0">
                            <li>Follow us</li>
                            {instagram ? <li><a target="_blank" rel="noreferrer" href={instagram}><i className="fa-brands fa-instagram followicon"></i></a></li> : null}
                            {facebook ? <li><a target="_blank" rel="noreferrer" href={facebook}><i className="fa-brands fa-facebook followicon"></i></a></li> : null}
                            {twitter ? <li><a target="_blank" rel="noreferrer" href={twitter}><i className="fa-brands fa-twitter followicon"></i></a></li> : null}
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 c5 mb-md-0 mb-3">
                        <h4 className="text-deco"><span>Services</span></h4>
                        <ul className="pt-0 list-unstyled">
                            <li>Tax Preparation Service</li>
                            <li>Tax Consulting Services</li>
                            <li>Tax Planning Services</li>
                            <li>Income Tax Return</li>
                            <li>Personal Income Tax</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 c5 mb-md-0 mb-3">
                        <h4 className="text-deco"><span>Useful Links</span></h4>
                        <ul className="pt-0 list-unstyled">
                            <li><Link to="about">About us </Link></li>
                            <li><Link to="privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="contact">Contact us</Link></li>
                            <li><Link to="register">Register</Link></li>
                            <li>  <a href={process.env.REACT_APP_LOGIN_URL} rel="noreferrer" target="_blank" className=''>Login</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 c5 mb-md-0 mb-3">
                        <h4 className="text-deco"><span>Contact us</span> </h4>
                        <ul className="pt-0 list-unstyled">
                            <li className="d-flex gap-2 align-items-start">
                                <i className="fa-solid fa-phone mt-2"></i>
                                <a href={`tel:${phone}`}>{phone}</a>
                            </li>
                            <li className="d-flex gap-2 align-items-start">
                                <i className="fa-solid fa-envelope mt-2"></i>
                                <a href={`mailto:${email}`}>{email}</a>
                            </li>
                            <li className="d-flex gap-2 align-items-start">
                                <i className="fa-solid fa-location-pin mt-2"></i>
                                <span>{address}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <ScrollButton />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <p className="pt-3">{copyright}</p>
                        </div>
                        <div className="col-lg-6 ">
                            <p className="pt-3 text-end">
                                Delvelop By : <a rel="noreferrer" className="" href="https://adiyogitechnosoft.com" target="_blank">Adiyogi Technosoft Pvt. Ltd. </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;