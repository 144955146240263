
import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import AxiosHelper from '../../helper/AxiosHelper'

const initialState = {
    user: {
        name: "",
        email: "",
        mobile: "",
        state_id: null,
        city_id: null,
        user_balance: 0,
        image: "",
        state_name: null,
        city_name: null,
    },
    token: localStorage.getItem('token'),
    useLoaded: false,
    status: "idle", // idle | loading | succeeded | failed
    error: ""
}

export const fetchUser = createAsyncThunk("cart/fetchUser", async () => {
    const { data } = await AxiosHelper.getData(`/customer/profile`);
    if (data?.status === true) {
        return data?.data;
    }
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.useLoaded = true;
            state.user = action.payload;
        },
        setToken: (state, action) => {
            localStorage.setItem('token', action.payload);
            state.token = action.payload;
        },
        logout: (state) => {
            localStorage.removeItem('token');
            state.user = initialState.user;
            state.token = null;
            state.useLoaded = false;
            state.status = "idle"
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUser.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.useLoaded = true;
                state.user = action.payload;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })

    }
})

export const { setUser, setToken, logout } = userSlice.actions
export default userSlice.reducer;