
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;

export const FILE_SIZE = 1024 * 1024;
export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

export const StringLimit = (text, len = 50) => {
    return text ? text.slice(0, len) + (text.length > len ? "..." : "") : '';
}

export const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export const round = (number, len = 100) => {
    return parseFloat(Math.round(number * len) / len).toFixed(2);
}

export const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

export const formatDateDDMMYYYY = (d) => {
    var date = new Date(d);

    if (isNaN(date.getTime())) {
        return d;
    }
    else {
        var month = [];
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sept";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";
        var day = date.getDate();

        if (day < 10) {
            day = "0" + day;
        }

        return day + " " + month[date.getMonth()] + ", " + date.getFullYear();
    }
}

export const changeTimeZone = (date, timeZone = 'UTC') => {
    if (typeof date === 'string') {
        return new Date(
            new Date(date).toLocaleString('en-US', {
                timeZone,
            }),
        );
    }

    return new Date(
        date.toLocaleString('en-US', {
            timeZone,
        }),
    );
}

export const formatDateCustom = (date) => {
    let dateObj = new Date(date);
    let hours = dateObj.getHours();
    let am_pm = 'AM';
    if (hours > 12) {
        hours = hours - 12;
        am_pm = 'PM';
    }

    hours = String(hours === 0 ? 12 : hours).padStart(2, "0")
    return `${dateObj.getDate()} ${months[dateObj.getMonth()]}, ${dateObj.getFullYear()} ${hours}:${String(dateObj.getMinutes()).padStart(2, "0")} ${am_pm}`;
}

export const padTo2Digits = num => {
    return num.toString().padStart(2, '0');
}

export const formatDate = d => {
    var date = new Date(Date.parse(d));
    var h = date.getHours()
    var am_pm = "AM"

    if (date.getHours() > 12) {
        h = (date.getHours() - 12);
        am_pm = "PM";
    }

    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(h),
            padTo2Digits(date.getMinutes())
        ].join(':') +
        ' ' + am_pm
    );
}
