import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { hideModal } from '../app/redux/loginModalSlice';
import AxiosHelper from "../helper/AxiosHelper";
import { toast } from "react-toastify";
import { phoneRegExp } from '../helper/StringHelper';
import { setToken, setUser } from '../app/redux/userSlice';
import { Link, useNavigate } from 'react-router-dom';

const SignupSchema = Yup.object().shape({
    mobile: Yup.string()
        .typeError('Phone number is not valid')
        .matches(phoneRegExp, "Phone number is not valid")
        .required('Please enter your phone number.')
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits'),
    otp: Yup.number()
        .typeError('OTP must be a number')
        .required('Please enter OTP.')
});

const LoginForm = ({ goBack = false, CloseButtonShow = false, showlabel = true }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [isDisbaled, setIsDisbaled] = useState(false)
    return (
        <Formik
            initialValues={{ mobile: '', otp: '' }}
            validationSchema={SignupSchema}
            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                var { data } = await AxiosHelper.postData('customer/login', values);
                if (data.status === true) {
                    resetForm()
                    toast.success(data.message);
                    dispatch(setUser(data.data.user))
                    dispatch(setToken(data.data.token))
                    dispatch(hideModal())
                    navigate('/home')
                } else {
                    setErrors(data.data)
                    toast.error(data.message);
                }
            }}
        >
            {({ values, setErrors, errors, setFieldValue }) => (
                <Form>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <label htmlFor="exampleInputEmail1" className={showlabel ? 'form-label' : 'd-none'}>Mobile Number</label>
                            <Field name="mobile" maxLength={10} type="text" className="form-control shadow-none rm-number" placeholder="Mobile Number" />
                            <small className="text-danger"><ErrorMessage name="mobile" /></small>
                        </div>
                        <div className="col-md-8 mb-3">
                            <label htmlFor="exampleInputPassword1" className={showlabel ? 'form-label' : 'd-none'}>Password</label>
                            <Field name="otp" maxLength={6} type="text" className="form-control shadow-none rm-number" placeholder="OTP" ></Field>
                            <small className="text-danger"><ErrorMessage name="otp" /></small>
                        </div>
                        <div className="col-md-4 mb-2 text-end">
                            <label htmlFor="exampleInputPassword1" className={showlabel ? 'form-label d-block' : 'd-none'}><br /></label>
                            <button type="button" className='btn btn-outline-primary' onClick={async () => {
                                if (!values.mobile) {
                                    setErrors({ ...errors, mobile: "Please enter mobile number." })
                                    return toast.error("Please enter mobile number.");
                                }

                                let { data } = await AxiosHelper.postData("customer/send-otp", values);
                                if (data.status === true) {
                                    setIsDisbaled(true);
                                    setTimeout(() => {
                                        setIsDisbaled(false);
                                    }, 25000);
                                    toast.success(data.message);
                                    return null;
                                }
                                else {
                                    setErrors(data.data)
                                    toast.error(data.message);
                                }
                            }} disabled={isDisbaled} >Send OTP {isDisbaled ? <i className="fa fa-spinner fa-spin ms-1"></i> : null} </button>
                        </div>
                        <div className="col-md-12 text-start">
                            <Link to="/register" className='text-decoration-none' onClick={() => dispatch(hideModal())}>Don't have account..!!</Link>
                        </div>
                        <div className="col-md-12 border-top pt-3 text-end">
                            <button type="submit" className="btn btn-primary me-2">Submit</button>
                            {goBack ? <BackButton /> : null}
                            {CloseButtonShow ? <CloseButton /> : null}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export function BackButton() {
    const navigate = useNavigate();
    const handleClose = () => navigate('/home');
    return (
        <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-arrow-left me-2"></i>
            Back To Home
        </Button>
    )
}

export function CloseButton() {
    const dispatch = useDispatch();
    const handleClose = () => dispatch(hideModal());
    return (
        <Button variant="secondary" onClick={handleClose}>
            Close
        </Button>
    )
}

export default LoginForm