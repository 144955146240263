import { useSelector } from 'react-redux'
const metaDescription = document.querySelector('meta[name="description"]');
const metaTitle = document.querySelector('meta[name="title"]');
const metaIcons = document.querySelectorAll('link[meta="icon"]')
const metaKeywords = document.querySelectorAll('link[meta="keywords"]')

const MetaTags = () => {
    const { setting } = useSelector((state) => state.setting)

    // meta Title, Description and Keywords
    metaDescription && (metaDescription.content = setting?.meta_description)
    metaTitle && (metaTitle.content = setting?.meta_title)
    metaKeywords && (metaKeywords.content = setting?.meta_keyword)

    // Dymamic meta Icons
    var i = metaIcons.length;
    if (i > 0) {
        while (i--) {
            metaIcons[i].href = setting.base_path + setting.favicon;
        }
    }

    // Dynamic Title
    document.title = setting.application_name
    return null
}

export default MetaTags