
import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import AxiosHelper from '../../helper/AxiosHelper'
import { toast } from 'react-toastify'

const initialState = {
    cart: [],
    coupon_code: null,
    summery: {
        discount: 0,
        delivery: 0,
        sub_total: 0,
        tax: 0,
        total: 0
    },
    count: 0,
    status: "idle", // idle | loading | succeeded | failed
    error: "",
    message: ""
}

export const fetchCart = createAsyncThunk("cart/fetchCart", async () => {
    const { data } = await AxiosHelper.getData(`/customer/cart`);
    if (data?.status === true) {
        return data?.data;
    }
})

export const addCart = createAsyncThunk("cart/addCart", async (payload, { dispatch }) => {
    const { data } = await AxiosHelper.postData(`/customer/cart`, { qty: 1, ...payload });
    if (data?.status === true) {
        toast.success(data?.message);
        dispatch(fetchCart())
        return data?.data;
    } else {
        toast.error(data?.message);
    }
})

export const updateCart = createAsyncThunk("cart/updateCart", async (payload, { dispatch }) => {
    const { data } = await AxiosHelper.putData(`/customer/cart`, payload);
    if (data?.status === true) {
        toast.success(data?.message);
        dispatch(fetchCart())
        return data?.data;
    } else {
        toast.error(data?.message);
    }
})

export const removeCart = createAsyncThunk("cart/removeCart", async (payload, { dispatch }) => {
    const { data } = await AxiosHelper.deleteData(`/customer/cart`, payload);
    if (data?.status === true) {
        toast.success(data?.message);
        dispatch(fetchCart())
        return data?.data;
    } else {
        toast.error(data?.message);
    }
})

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        // ==> normal reducer functions go here
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCart.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(fetchCart.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.cart = action.payload.cart;
                state.summery = action.payload.summery
                state.count = action.payload?.cart?.length;
                state.coupon_code = action.payload?.coupon_code
            })
            .addCase(fetchCart.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
            .addCase(addCart.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(addCart.fulfilled, (state, action) => {
                state.count = action.payload;
                state.status = "succeeded"
            })
            .addCase(addCart.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
            .addCase(updateCart.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(updateCart.fulfilled, (state, action) => {
                state.count = action.payload;
                state.status = "succeeded"
            })
            .addCase(updateCart.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
            .addCase(removeCart.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(removeCart.fulfilled, (state, action) => {
                state.count = action.payload;
                state.status = "succeeded"
            })
            .addCase(removeCart.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
    }
})

export default cartSlice.reducer;