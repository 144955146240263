import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from "react";
import { NavDropdown, Dropdown, Modal } from 'react-bootstrap';
import { showModal } from '../app/redux/loginModalSlice';
import { logout } from '../app/redux/userSlice';
import { hideModal } from '../app/redux/loginModalSlice';
import LoginForm from './LoginForm';

const Header = () => {

    const { setting } = useSelector((state) => state.setting)
    const { count } = useSelector((state) => state.cart)
    const { token, user } = useSelector((state) => state.user)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    let { logo, application_name, base_path, phone, email, facebook, twitter, instagram, linkdin } = setting;

    const { pathname } = useLocation();
    const [show, setShow] = useState(true)
    useEffect(() => {
        if (window.innerWidth < 767) {
            const handleScroll = event => {
                if (window.scrollY > 30) {
                    setShow(false)
                } else {
                    setShow(true)
                }
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const logOut = () => {
        dispatch(logout())
        setTimeout(() => navigate('/login'), 100)
    }

    return (
        <>
            <nav className={`${show ? 'd-block' : 'd-none'} text-light w-100 top-nav`}>
                <div className="container-lg">
                    <div className="d-flex gap-2 py-2">
                        <div className="border-end border-white border-2 pe-2">
                            <a href={`tel:${phone}`} className="text-decoration-none text-light fs-6">
                                <i className="fa fa-phone me-2"></i>
                                <small className='d-none d-md-inline-block'> {phone}</small>
                            </a>
                        </div>
                        <div className="">
                            <a href={`mailto:${email}`} className="text-decoration-none text-light">
                                <i className="fa fa-envelope-open me-2"></i>
                                <small className='d-none d-md-inline-block'>{email}</small>
                            </a>
                        </div>

                        <div className="offset-2 d-flex align-items-center me-0 ms-auto social-size gap-3">
                            <ul className="m-0 p-0 list-unstyled d-flex gap-3">
                                <li className="me-2">
                                    <Link to="/cart" className="cart-icon d-none d-md-block" data-count={count}>
                                        <i className="fa fa-cart-shopping"></i>
                                    </Link>
                                </li>

                                {twitter ? <li className="">
                                    <a href={twitter} rel="noreferrer" target="_blank" className='text-light text-decoration-none'><i className="fa-brands fa-twitter "></i></a>
                                </li> : null}

                                {facebook ? <li className="">
                                    <a href={facebook} rel="noreferrer" target="_blank" className='text-light text-decoration-none'><i className="fa-brands fa-facebook "></i></a>
                                </li> : null}

                                {instagram ? <li className="">
                                    <a href={instagram} rel="noreferrer" target="_blank" className='text-light text-decoration-none'><i className="fa-brands fa-instagram "></i></a>
                                </li> : null}

                                {linkdin ? <li className="">
                                    <a href={linkdin} rel="noreferrer" target="_blank" className='text-light text-decoration-none'><i className="fa-brands fa-linkedin "></i></a>
                                </li> : null}

                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <Navbar fixed="top" className={show ? 'fixed-top-custom py-0' : ''} collapseOnSelect expand="md">
                <div className="container-lg pb-0 pb-lg-0">
                    <Navbar.Brand as={Link} to="home" className="navbrand pe-4">
                        {!logo ?
                            <img src={'img/logo_2.png'} alt={application_name} /> :
                            <img src={base_path + logo} alt={application_name} />
                        }
                    </Navbar.Brand>
                    <div className='d-flex d-md-none align-items-center gap-4 justify-content-end' style={{ minWidth: 135 }}>
                        <Link to="/cart" className="btn btn-outline-primary btn-icon  cart-icon text-primary" data-count={count}>
                            <i className="fa fa-cart-shopping"></i>
                        </Link>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    </div>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto gap-0 gap-md-4">
                            <Nav.Item>
                                <Nav.Link className={`nav-link py-2 py-lg-0 ${pathname === '/home' ? "active" : ""}`} as={Link} to="home">Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className={`nav-link py-2 py-lg-0 ${pathname === '/about' ? "active" : ""}`} as={Link} to="about">About us</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className={`nav-link py-2 py-lg-0 ${pathname === '/products' ? "active" : ""}`} as={Link} to="products">E-Store</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className={`nav-link py-2 py-lg-0 ${pathname === '/contact' ? "active" : ""}`} as={Link} to="contact">Contact</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        {token ?
                            <NavDropdown title={<UserIcon user={user} />} className="py-0 text-white">
                                <NavDropdown.Item as={Link} to="profile">Profile</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="my-orders">My Orders</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="my-wallet">My Wallet</NavDropdown.Item>
                                <NavDropdown.Item onClick={logOut}>Logout</NavDropdown.Item>
                            </NavDropdown>
                            : <div className='d-flex gap--2 mb-2 mb-md-0'>
                                <Dropdown className='me-2'>
                                    <Dropdown.Toggle className="px-4" variant="orange" id="dropdown-login">Login</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => dispatch(showModal())}>As Customer</Dropdown.Item>
                                        <Dropdown.Item href={process.env.REACT_APP_LOGIN_RETAILER_URL} rel="noreferrer" target="_blank" >As Retailer</Dropdown.Item>
                                        <Dropdown.Item href={process.env.REACT_APP_LOGIN_DISTRIBUTOR_URL} rel="noreferrer" target="_blank" >As Distributor</Dropdown.Item>
                                        <Dropdown.Item href={process.env.REACT_APP_LOGIN_MDISTRIBUTOR_URL} rel="noreferrer" target="_blank" >As Main Distributor</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle className="px-4" variant="orange" id="dropdown-register">Register</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="register">As Customer</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="retailer/register">As Retailer</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>}
                    </Navbar.Collapse>
                </div>
            </Navbar >
            <LoginModal />
        </>
    )
}

export const UserIcon = ({ user }) => {
    return <div className='d-flex align-items-center gap-2 border rounded'>
        <img width={40} className="img-thumbnail1" src={user?.image} alt={user?.name} />
        <h6 className="mb-0 text-primary me-2 profile-icon-text">{user?.name}</h6>
    </div>
}

export const LoginModal = () => {
    const { value } = useSelector((state) => state.loginModal)

    const dispatch = useDispatch();
    const handleClose = () => dispatch(hideModal());

    return (
        <Modal centered show={value} onHide={handleClose}>
            <Modal.Header className="py-2" closeButton>
                <Modal.Title>Customer Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoginForm CloseButtonShow={true} />
            </Modal.Body>
        </Modal>
    )
}

export default Header;