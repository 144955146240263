import Footer from "../components/Footer";
import Header from "../components/Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetaTags from "../components/MetaTags";

const Main = ({ current }) => {
	return (
		<>
			<Header />
			<main>{current}</main>
			<Footer />
			<ToastContainer />
			<MetaTags />
		</>
	)
}

export default Main;