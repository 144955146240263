import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { fetchCart, removeCart, updateCart } from '../../app/redux/cartSlice';
import { StringLimit, formatDateCustom, round } from '../../helper/StringHelper';
import { Button, Modal } from 'react-bootstrap';
import AxiosHelper from '../../helper/AxiosHelper';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validSchema = Yup.object().shape({
    code: Yup.string().required('Please Enter Voucher Code First.'),
});

const Cart = () => {

    const dispatch = useDispatch()
    const { cart, summery, coupon_code } = useSelector((state) => state.cart);
    const [showOffers, setShowOffers] = useState(false)
    const [coupons, setCoupons] = useState([])

    const hideOffers = () => setShowOffers(false);
    const submitVoucherCode = async (values, { setFieldError }) => {
        var { data } = await AxiosHelper.postData(`customer/apply-voucher`, values);
        if (data.status === true) {
            dispatch(fetchCart())
            toast.success(data.message);
        } else {
            setFieldError('code', data.message);
        }
    }

    const getVoucherCodes = async () => {
        if (coupons.length === 0) {
            var { data } = await AxiosHelper.getData(`customer/vouchers`);
            if (data.status === true) {
                setCoupons(data.data);
                setShowOffers(true)
            } else {
                toast.error(data.message);
            }
        } else {
            setShowOffers(true)
        }
    }

    const removeVoucher = async () => {
        if (coupon_code) {
            var { data } = await AxiosHelper.getData(`customer/remove-voucher`);
            if (data.status === true) {
                dispatch(fetchCart())
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        }
    }

    return (
        <section style={{ marginTop: 120 }}>
            <div className='container my-5'>
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-1">Shopping Cart</h5>
                            <Link to="/products" className="btn btn-outline-primary">
                                <i className="fa fa-arrow-left me-2" />
                                Go Back
                            </Link>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12 cartItems">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p>You have {cart?.length} items in your cart</p>
                                </div>
                                {cart?.map((item, i) => <CartItem key={i} item={item} />)}
                                {cart?.length > 0 ? <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            {coupon_code ? <div className="col-md-6 mb-lg-0 mb-2">
                                                <p className="text-muted">Promotion Applied</p>
                                                <div className="">
                                                    <div className="bg-gray p-2 d-flex align-items-center gap-2 rounded border border-width-2px border-dashed border-success fs-6">
                                                        <i className="fa fa-tag text-success fs-5 rounded-circle icon" style={{ '--avatar-size': '40px' }}></i>
                                                        <span className="text-dark">{coupon_code}</span>
                                                        <span title="Remove" role="button" className="text-danger ms-auto me-2 fw-bold p-2" onClick={removeVoucher}>
                                                            <i className="fa fa-remove"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div> : <div className="col-md-6 mb-lg-0 mb-2">
                                                <p>Have a Promo / Voucher Code. </p>
                                                <Formik
                                                    initialValues={{ code: '' }}
                                                    validationSchema={validSchema}
                                                    onSubmit={submitVoucherCode}
                                                >
                                                    {({ errors, touched }) => (
                                                        <Form>
                                                            <Field type="text" name="code" placeholder="Voucher Code" className="form-control" />
                                                            <div className="invalid-feedback d-block"><ErrorMessage name="code" /></div>

                                                            <button type="submit" className="btn btn-outline-warning mt-2 me-2">Apply</button>
                                                            <button type="button" className="btn btn-outline-success mt-2" onClick={getVoucherCodes}>Show Offers</button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                            }
                                            <div className="col-md-6">
                                                <ul className="list-group list-group-flush">

                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        <span>Sub Total</span>
                                                        <span> <i className="fa fa-inr me-1" />{round(summery?.sub_total)}</span>
                                                    </li>

                                                    {parseInt(summery?.tax) > 0 &&
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span>Tax Amount</span>
                                                            <span><i className="fa fa-inr me-1" />{round(summery?.tax)}</span>
                                                        </li>
                                                    }

                                                    {parseInt(summery?.delivery) > 0 &&
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span>Delivery Charge</span>
                                                            <span><i className="fa fa-inr me-1" />{round(summery?.delivery)}</span>
                                                        </li>
                                                    }

                                                    {parseInt(summery?.discount) > 0 &&
                                                        <li className="list-group-item d-flex justify-content-between align-items-center text-success">
                                                            <span>Discount</span>
                                                            <span>- <i className="fa fa-inr me-1" />{round(summery?.discount)}</span>
                                                        </li>
                                                    }

                                                    <li className="list-group-item d-flex justify-content-between align-items-center fw-bold list-group-item-dark">
                                                        <span className="fs-6">Total</span>
                                                        <span className="fs-6"><i className="fa fa-inr me-1" />{round(summery?.total)}</span>
                                                    </li>

                                                    <div className="float-right">
                                                        <Link to="/products" className="btn btn-lg btn-secondary md-btn-flat mt-2 me-2">Back to Shopping</Link>
                                                        <Link to="/checkout" className="btn btn-lg btn-primary mt-2">Checkout</Link>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal centered scrollable show={showOffers} onHide={hideOffers}>
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title className='fs-5'>Promo / Voucher Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {coupons.map((row, i) => (
                            <div className="col-md-12 mb-3" key={i}>
                                <div className="card box">
                                    <div className="ribbon ribbon-top-right" style={{ '--ribbon-color': 'red' }}>
                                        <span>Offer</span>
                                    </div>
                                    <div className="card-body p-3">
                                        <div className="d-flex flex-column gap-1 justify-content-between">
                                            <h6 className="mb-0 text-red">{row?.name}</h6>
                                            <div className="small text-muted mb-2">{StringLimit(row?.description, 150)}</div>
                                            <div className="d-flex justify-content-between align-items-end">
                                                <p className="mb-0 px-2 py-1 coupon-code text-dark bg-gray">{row?.code}</p>
                                                <div>
                                                    <small className="d-block text-success">Expire: <span className='ms-2'>{formatDateCustom(row?.starts_at)}</span> </small>
                                                    <small className="d-block text-danger">Expire: <span className='ms-2'>{formatDateCustom(row?.expires_at)}</span> </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideOffers}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section >
    )
}


const CartItem = ({ item }) => {

    const dispatch = useDispatch()
    const [qty, setQty] = useState(1);

    useEffect(() => {
        setQty(item.qty)
    }, [item]);

    useEffect(() => {
        if (item.qty !== qty) {
            const timer = setTimeout(() => dispatch(updateCart({ product_id: item?.product_id, qty })), 1000)
            return () => clearTimeout(timer)
        }
    }, [dispatch, item, qty]);

    const qtyChange = (e) => {
        let { value } = e.currentTarget;
        let min = Math.max(1, parseInt(item?.product?.minimum))
        let max = Math.min(parseInt(item?.product?.stock), parseInt(item?.product?.maximum))
        if (value === '-') {
            if (qty > min) setQty(prev => prev - 1);
        } else if (value === '+') {
            if (qty < max) setQty(prev => prev + 1);
        } else {
            value = parseInt(value)
            if (value < min) {
                setQty(min);
            } else if (value < max) {
                setQty(max);
            } else if (value > min && value < max) {
                setQty(value);
            }
        }
    }

    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-8 mb-lg-0 mb-3">
                        <div className="d-flex flex-row align-items-center">
                            <div>
                                <img src={item?.product?.main_image?.image} className="img-fluid rounded-3 aspect-ratio-1" alt="" style={{ width: 65, minWidth: 65 }} />
                            </div>
                            <div className="ms-3">
                                <h6>
                                    <Link className='text-decoration-none small' to={`/product/${item?.product?.slug}`}>
                                        {item?.product?.name}
                                    </Link>
                                </h6>
                                <p className="fs--1 mb-0">
                                    <span className="text-secondary pe-2 small me-2 border-end">
                                        Brand : {item?.product?.brand?.name}
                                    </span>
                                    <span role="button" className="text-danger me-2 small" onClick={() => dispatch(removeCart({ product_id: item?.product_id }))}>
                                        Remove
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-between align-items-center">
                        <div className="btn-group" role="group" style={{ width: 120 }}>
                            <button type="button" className="btn btn-sm btn-outline-primary" onClick={e => qtyChange(e)} value="-">
                                <i className="fa fa-minus" />
                            </button>
                            <input type="number" value={qty} style={{ maxWidth: 60 }} onChange={CartItem} className="form-control text-center rm-number border-primary form-control-sm rounded-0 border-end-0 border-start-0 px-0" />
                            <button type="button" className="btn btn-sm btn-outline-primary" onClick={e => qtyChange(e)} value="+">
                                <i className="fa fa-plus" />
                            </button>
                        </div>
                        <h6 className="mb-0">
                            ₹ {round(item?.sub_total)}<br />
                            <small className="text-muted fs--1">₹ {round(parseFloat(item?.sub_total) / parseInt(item?.qty))} / Item</small>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart