import { configureStore } from '@reduxjs/toolkit';
import loginModalReducer from './redux/loginModalSlice';
import settingReducer from './redux/settingSlice';
import cartReducer from './redux/cartSlice';
import userReducer from './redux/userSlice';

export const store = configureStore({
    reducer: {
        loginModal: loginModalReducer,
        user: userReducer,
        setting: settingReducer,
        cart: cartReducer,
    },
    devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
})

