import { createSlice } from '@reduxjs/toolkit'

export const loginModalSlice = createSlice({
    name: 'loginModal',
    initialState: { value: false },
    reducers: {
        showModal: (state) => {
            state.value = true;
        },
        hideModal: (state) => {
            state.value = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const { showModal, hideModal } = loginModalSlice.actions
export default loginModalSlice.reducer