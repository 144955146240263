import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import React, { Suspense, useEffect } from 'react';
import Main from "./layout/Main";
import Loader from "./components/Loader";
// import Post from "./pages/Post";
import { fetchSetting } from './app/redux/settingSlice';
import { fetchCart } from './app/redux/cartSlice';
import { useDispatch, useSelector } from "react-redux";
import CheckAuth from "./layout/CheckAuth";
import Cart from "./pages/User/Cart";
import { fetchUser } from "./app/redux/userSlice";

const About = React.lazy(() => import("./pages/About"));
const Home = React.lazy(() => import("./pages/Home"));
const Services = React.lazy(() => import("./pages/Services"));
const ServiceDetail = React.lazy(() => import("./pages/ServiceDetail"));
const Blogs = React.lazy(() => import("./pages/Blogs"));
const BlogDetail = React.lazy(() => import("./pages/BlogDetail"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Register = React.lazy(() => import("./pages/Register"));
const RetailerRegister = React.lazy(() => import("./pages/RetailerRegister"));
const Error404 = React.lazy(() => import("./pages/Error404"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Products = React.lazy(() => import("./pages/Products"));
const ProductsDetails = React.lazy(() => import("./pages/ProductsDetails"));
const Profile = React.lazy(() => import("./pages/User/Profile"));
const MyOrders = React.lazy(() => import("./pages/User/MyOrders"));
const CheckOut = React.lazy(() => import("./pages/User/CheckOut"));
const MyWallet = React.lazy(() => import("./pages/User/MyWallet"));
const CustomerLogin = React.lazy(() => import("./pages/CustomerLogin"));

const App = () => {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.user)

    useEffect(() => {
        dispatch(fetchSetting());
        if (token) {
            dispatch(fetchUser());
            dispatch(fetchCart());
        }
    }, [dispatch, token])

    const basename = document.querySelector('base')?.getAttribute('href') ?? '/'

    return (
        <Suspense fallback={<Loader />}>
            <BrowserRouter basename={basename}>
                <Routes>
                    <Route path="/" element={<Main current={<Outlet />} />}>
                        <Route path="/" element={<Home />} />
                        <Route path="home" element={<Home />} />
                        <Route path="about" element={<About />} />
                        <Route path="services" element={<Services />} />
                        <Route path="blogs" element={<Blogs />} />
                        <Route path="contact" element={<Contact />} />
                        <Route path="privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="service-details/:slug" element={<ServiceDetail />} />
                        <Route path="blog-details/:slug" element={<BlogDetail />} />
                        <Route path="products" element={<Products />} />
                        <Route path="product/:slug" element={<ProductsDetails />} />

                        <Route path="/" element={<CheckAuth type={false} current={<Outlet />} />}>
                            <Route path="register" element={<Register />} />
                            <Route path="retailer/register" element={<RetailerRegister />} />
                            <Route path="login" element={<CustomerLogin />} />
                        </Route>

                        <Route path="/" element={<CheckAuth type={true} current={<Outlet />} />}>
                            <Route path="cart" element={<Cart />} />
                            <Route path="checkout" element={<CheckOut />} />
                            <Route path="profile" element={<Profile />} />
                            <Route path="my-orders" element={<MyOrders />} />
                            <Route path="my-wallet" element={<MyWallet />} />
                        </Route>
                        <Route path="*" element={<Error404 />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </Suspense>
    )
}

export default App;